import * as React from 'react'
import Seo from '../components/seo'
import { H1, P } from '../components/common/text'

const NotFoundPage = () => (
  <>
    <Seo title='404: Not found' />
    <H1>404: Not Found</H1>
    <P>You just hit a route that doesn&#39;t exist... the sadness.</P>
  </>
)

export default NotFoundPage
